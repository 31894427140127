export const inputInitialState = {
  appName: '',
  orgName:'',
  baseUrl: '',
  destinationPath: '',
  appBar: {
    title: '',
    showSidebar: false,
    sideBarWidth: 0,
  },
  auth: {
    isAuthenticate: false,
  },
  sideBar: {
    navItems: [{ title: '', href: '' }],
  },
  modules: [],
}

export const moduleInitialState = {
      name: "",
      api: {
       
      },
      listDataPath:[],
      payloadPath: "",
      successRedirect: "",
      failureRedirect: '',
      form: [
      
      ],
      list: [
      ],
    }
export const listInitialState = {
  componentName: 'Grid',
  props: {
    container: true,
    rowSpacing: 2,
    columnSpacing: 1,
  },
  children: [
    {
      componentName: 'Grid',
      props: {
        container: true,
        direction: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingBottom: 2,
      },
      children: [
        {
          componentName: 'NavigationButton',
          props: {
            variant: 'contained',
            type: 'submit',
            redirectTo: '/TransformationList/create',
          },
          children: ['Create transformation'],
        },
      ],
    },
    {
      componentName: 'EnhancedTable',
      props: {
        container: true,
        rowSpacing: 2,
        columnSpacing: 1,
        uniqueId: 'id',
        columns: [
          {
            redirectTo: '',
            id: '',
            align: 'left',
            disablePadding: false,
            label: '',
          },
          {
            align: 'left',
            label: 'apiId',
            id: 'apiId',
          },
          {
            align: 'left',
            id: 'apiHost',
            label: 'apiHost',
          },
          {
            align: 'left',
            label: 'apiPath',
            id: 'apiPath',
          },
        ],
        title: "Transformations's",
        rowsPerPage: 20, 
      },
    },
  ],
}