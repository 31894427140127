// YamlViewer.js
import React, { useState, useEffect } from 'react';


const YamlViewer = ({ url }) => {
  const [yamlContent, setYamlContent] = useState(); // Initialize with loading text
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        url: url,
      }),
    };

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          'https://scaffolding-api.fincuro.com/api/get_yaml',
          requestOptions
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch the URL. Status: ${response.status}`);
        }

        const content = await response.json();
        setYamlContent(content);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching or parsing YAML:', error);
        setYamlContent('Error loading YAML'); // Update the content in case of an error
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div
      style={{
        background: 'black',
        borderRadius: '20px',
        color: 'white',
        width: '608px',
        height: '350px',
        overflow: 'auto',
        whiteSpace: 'pre-wrap', 
        padding: '1rem',
         
      }}
    >
      <h2>YAML Content:</h2>
      <code>{JSON.stringify(yamlContent, null, 2)}</code>
    </div>
  );
};

export default YamlViewer;
