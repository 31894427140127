import React, { useState } from "react";
import "./RootForm.css";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../constants/constants";
import { useHistory } from "react-router-dom";
const TextFieldStyled = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
    "& .MuiSelect-select": {
      fontFamily: "Ubuntu, sans-serif",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  width: "100%",
});
const ButtonStyled = styled(Button)({
  borderRadius: "30px",
  padding: "8px 40px",
  backgroundColor: "#1C242C",
  color: "#fff",
  cursor: "pointer",
  height: "45px",
  flexGrow: "0",
  fontFamily: "Ubuntu, sans-serif",
  width: "172px",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "normal",
});

const steps = ["Select Application Type", "Next Step"];

function RootForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [applicationType, setApplicationType] = useState("");
  const [rootApplication, setRootApplication] = useState("");
  const [auth, setAuth] = useState("");
  const [region, setRegion] = useState("");
  const [userPoolId, setUserPoolId] = useState("");
  const [userPoolClientId, setUserPoolClientId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [style, setStyle] = useState("");
  const [mfeConfigs, setMfeConfigs] = useState([
    { applicationName: "", path: "", description: "", MFEUrl: "" },
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract existing query parameters
  const queryParams = new URLSearchParams(location.search);
  const appType = queryParams.get("appType");

  // Replace the current query string with the updated one

  const generateRootApp = async () => {
    try {
      const data = {
        data: {
          orgName: rootApplication,
          style: style,
          authentication: {
            type: auth,
            cognito_params: {
              region: region,
              userPoolId: userPoolId,
              userPoolClientId: userPoolClientId,
            },
          },
          application_mfe: mfeConfigs,
        },
      };
      const response = await fetch(
        `${BASE_URL}/generate/rootConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };
      const response2 = await fetch(
        `${BASE_URL}/api/create-zip`,
        requestOptions
      );

      if (!response2.ok) {
        throw new Error("Failed to create zip");
      }

      const response3 = await fetch(
        `${BASE_URL}/api/upload-to-s3`,
        requestOptions
      );
      navigate(`/download?applicationName=${rootApplication}`);
      if (!response3.ok) {
        throw new Error("Failed to upload to S3");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const rootData = {data:{
  //   orgName:rootApplication,
  //   authentication:{
  //     type:auth,
  //     cognito_params:{
  //       region,
  //       userPoolId,
  //       userPoolClientId
  // }
  //   },
  //   application_mfe:{
  //     mfeConfigs
  //   }

  // }}
  // console.log(rootData,'rootData');
  const handleNext = () => {
    if (applicationType === "mfe application") {
      navigate("/home", { state: { applicationType } });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      // Replace the current query string with the updated one
      navigate(`/rootform?appType=root`);
    }

    if (activeStep == 1) {
      generateRootApp();
    }
  };

  const handleAddMfe = () => {
    setMfeConfigs([
      ...mfeConfigs,
      { applicationName: "", path: "", description: "", MFEUrl: "" },
    ]);
  };
  console.log(mfeConfigs);
  const handleBlur = (index) => {
    const mfeUrl = mfeConfigs[index]?.MFEUrl; 
    
    const urlSegments = mfeUrl.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];
    const path = lastSegment.split('.')[0].split('-').pop()
   
   
   
      const list = [...mfeConfigs];
      list[index]["path"] = path;
   
  
   
    setMfeConfigs(list);
  };
  const handleMfeChange = (index, field, value) => {
    const updatedConfigs = mfeConfigs.map((config, i) =>
      i === index ? { ...config, [field]: value } : config
    );
    setMfeConfigs(updatedConfigs);
  };

  const handleDeleteMfe = (index) => {
    setMfeConfigs(mfeConfigs.filter((_, i) => i !== index));
  };
  const handleAuthChange = (e) => {
    const selectedAuth = e.target.value;
    setAuth(selectedAuth);
    if (selectedAuth === "Azure AD" || selectedAuth === "KeyCloak") {
      setErrorMessage(`${selectedAuth} is currently not supported`);
    } else {
      setErrorMessage("");
    }
  };
  const handleChange = (e) => {
    setApplicationType(e.target.value);
  };

  const handleBackStep = () => {
    if (activeStep === 0) {
      navigate("/");
    } else {
      setActiveStep((prevStep) => prevStep - 1);
      navigate("/rootform")
    }
  };

  return (
    <Box className="form-wrapper">
      <Box className="paper-wrapper">
        <Box className="upper-cirle"></Box>
        <Box className="lower-cirle"></Box>
        <Box className="back-container">
          <button
            onClick={handleBackStep}
            style={{
              all: "unset",
              marginRight: "6rem",
              padding: "0",
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              color: "#000000",
              cursor: "pointer",

              flexGrow: "0",
              fontFamily: "Ubuntu, sans-serif",
              fontSize: "18px",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <svg
              width="10"
              height="19"
              viewBox="0 0 10 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17.625L2 9.3125L9 1"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            &nbsp; &nbsp; Back
          </button>
        </Box>

        {activeStep === 0 && (
          <Box sx={{ mt: 2 }}>
            <TextFieldStyled
              select
              label="MFE Application Type"
              name="applicationType"
              value={applicationType}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="mfe application">MFE Application</MenuItem>
              <MenuItem value="root application">Root Application</MenuItem>
            </TextFieldStyled>
          </Box>
        )}
        {activeStep === 1 && (
          <Box sx={{ mt: 2 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "400" }}>
                    Root Configuration
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Ubuntu", paddingRight: "50px" }}
                  >
                    {rootApplication}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TextFieldStyled
                  label="Root Application"
                  name="rootApplication"
                  value={rootApplication}
                  onChange={(e) => setRootApplication(e.target.value)}
                  variant="outlined"
                ></TextFieldStyled>
                <TextFieldStyled
                  select
                  label="Style your Root Application"
                  name="style"
                  value={style}
                  onChange={(e) => setStyle(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value="card">Card</MenuItem>
                  <MenuItem value="list">List</MenuItem>
                  <MenuItem disabled value="accordian">
                    Accordion
                  </MenuItem>
                </TextFieldStyled>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ArrowDownwardIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ fontFamily: "Ubuntu" }}>
                    Authentication mfe
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Ubuntu", paddingRight: "50px" }}
                  >
                    {auth}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <TextFieldStyled
                  select
                  label="Authentication MFE"
                  name="auth"
                  value={auth}
                  onChange={handleAuthChange}
                  variant="outlined"
                >
                  <MenuItem value="KeyCloak">KeyCloak</MenuItem>
                  <MenuItem value="cognito">AWS Cognito</MenuItem>
                  <MenuItem value="Azure AD">Azure AD</MenuItem>
                </TextFieldStyled>
                {errorMessage && (
                  <Typography
                    sx={{ fontStyle: "italic" }}
                    color="error"
                    variant="h6"
                  >
                    {errorMessage}
                  </Typography>
                )}

                {auth === "cognito" && (
                  <>
                    <TextFieldStyled
                      label="Region"
                      name="region"
                      value={region}
                      onChange={(e) => setRegion(e.target.value)}
                      variant="outlined"
                    />
                    <TextFieldStyled
                      label="User Pool ID"
                      name="userPoolId"
                      value={userPoolId}
                      onChange={(e) => setUserPoolId(e.target.value)}
                      variant="outlined"
                    />
                    <TextFieldStyled
                      label="User Pool Client ID"
                      name="userPoolClientId"
                      value={userPoolClientId}
                      onChange={(e) => setUserPoolClientId(e.target.value)}
                      variant="outlined"
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            {mfeConfigs.map((config, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ArrowDownwardIcon />
                    </div>
                  }
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontFamily: "Ubuntu" }}>
                      MFE Configuration {index + 1}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontFamily: "Ubuntu" }}>
                        {config.applicationName}
                      </Typography>
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMfe(index);
                        }}
                        style={{ marginRight: "8px" }}
                      >
                        <DeleteIcon sx={{ color: "red" }} />
                      </IconButton>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <TextFieldStyled
                    label="MFE Name"
                    name="applicationName"
                    value={config.applicationName}
                    onChange={(e) =>
                      handleMfeChange(index, "applicationName", e.target.value)
                    }
                    variant="outlined"
                    
                  />
                  <div style={{ display: "flex" }}>
                    <TextFieldStyled
                      label="Add mfe in the form of build js file link"
                      name="MFEUrl"
                      value={config.MFEUrl}
                      onChange={(e) =>
                        handleMfeChange(index, "MFEUrl", e.target.value)
                      }
                      onBlur={()=>{handleBlur(index)}}
                      variant="outlined"
                    />{" "}
                    <Tooltip
                      title={
                        <div>
                          1) Generate MFE application and download the zip file.{" "}
                          <br />
                          2) UnZip the downloaded zip file. <br />
                          3) Open the terminal or cmd navigate to the downloaded
                          MFE folder.
                          <br />
                          4) run the command : npm install.
                          <br />
                          5) Dist will be generated and
                          'organaisation-projectName'.js file will be created.
                          <br />
                          6) Local Hosting:<br />
                           1.run the command: npm start <br />
                           2.copy the the url https://localhost:89xx/organaisatio-projectName.js <br/>
                          7) Public hosting:<br />
	                        1.run the command : npm run build <br />
	                         2.upload the .js file in the s3 or google Drive or any other platform and share the link in below format format
                           https://domain..../organaisation-projectName.js 
                        </div>
                      }
                      arrow
                    >
                      <img
                        src="	https://img.icons8.com/?size=50&id=77&format=png"
                        style={{
                          height: "18px",
                          width: "18px",
                          marginTop: "18px",
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </div>
                  {/* <TextFieldStyled
                    label="MFE Path"
                    name="path"
                    value={config.path}
                    onChange={(e) =>
                      handleMfeChange(index, "path", e.target.value)
                    }
                    variant="outlined"
                  /> */}
                  <TextFieldStyled
                    label="Description"
                    name="description"
                    value={config.description}
                    onChange={(e) =>
                      handleMfeChange(index, "description", e.target.value)
                    }
                    variant="outlined"
                    wordLimit={10}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
            <Grid container sx={{ justifyContent: "flex-end" }}>
              <Grid item>
                {" "}
                <ButtonStyled
                  variant="contained"
                  color="primary"
                  onClick={handleAddMfe}
                  sx={{ marginTop: "1rem", textTransform: "none" }}
                >
                  Add MFE
                </ButtonStyled>
              </Grid>
            </Grid>
          </Box>
        )}

        {activeStep === 1 ? (
          <ButtonStyled
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            type="submit"
            sx={{ marginTop: "1rem", textTransform: "none" }}
            onClick={generateRootApp}
            disabled={!applicationType}
          >
            Generate
          </ButtonStyled>
        ) : (
          <ButtonStyled
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            type="submit"
            sx={{ marginTop: "1rem", textTransform: "none" }}
            onClick={handleNext}
            disabled={!applicationType || errorMessage}
          >
            Continue
          </ButtonStyled>
        )}
      </Box>
    </Box>
  );
}

export default RootForm;
