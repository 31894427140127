import React from "react";
import { useInputValues } from "../../contexts/inputContext";
import { Box, Button, Paper, Typography } from "@mui/material";
import Zip from "../../assets/Zip.png";
import Download from "../../assets/Download.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../constants/constants";
function DownloadPage() {
  const { inputValues, setInputValues } = useInputValues();
  const [searchParams] = useSearchParams();
  const applicationName = searchParams.get("applicationName");
  const navigate = useNavigate();
  const downloadZip = () => {
    fetch(`${BASE_URL}/api/download`)
      .then((res) => res.json())
      .then((json) => {
        if (json.downloadURL) {
          const link = document.createElement("a");
          link.href = json.downloadURL;

          link.setAttribute("download", "filename.ext");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
        } else {
          console.error(
            "No signed URL available. Make sure to fetch it first."
          );
        }
      });
  };
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/");
  };
  return (
    <Box sx={{ padding: "0px 275px 0px 275px", margin: "0" }}>
      <Typography
        sx={{
          fontFamily: "Ubuntu, sans-serif",
          fontWeight: "700",
          fontSize: "40px",
          lineHeight: "45.96px",
          color: "#1C242C",
          textAlign: "left",
          padding: "15px 0",
        }}
        variant="h4"
        component="h1"
        gutterBottom
      >
        UI Code Generator
      </Typography>
      <Paper
        sx={{
          padding: "45px",
          width: "100%",
          maxHeight: "50%",
          overflowY: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Ubuntu, sans-serif",
            fontWeight: "500",
            fontSize: "25px",
            lineHeight: "45.96px",
            color: "#1C242C",
            textAlign: "left",
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          Please find the below generated UI code
        </Typography>
        <Typography
          sx={{
            fontFamily: "Ubuntu, sans-serif",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "45.96px",
            color: "#1C242C",
            textAlign: "left",
          }}
          variant="h4"
          component="h1"
          gutterBottom
        >
          UI Code
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "50%",

              backgroundBlendMode: "screen",
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              padding: 1,
            }}
          >
            {" "}
            <img alt="Zip" src={Zip} />
            <Typography
              sx={{
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "45.96px",
                color: "#1C242C",
                textAlign: "left",
              }}
              variant="h4"
              component="h1"
              gutterBottom
            >
              {applicationName ? applicationName : inputValues?.appName}.Zip
            </Typography>
          </Box>
          <button
            style={{ backgroundColor: "white", marginLeft: "1rem" }}
            onClick={downloadZip}
          >
            <img
              sx={{ width: "24px", height: "24px" }}
              alt="Download"
              src={Download}
            />
          </button>
        </Box>
        <Button
          onClick={handleClick}
          variant="contained"
          color="primary"
          sx={{
            borderRadius: "30px",
            padding: "8px 40px",
            backgroundColor: "#1C242C",
            color: "#fff",
            cursor: "pointer",
            height: "45px",
            flexGrow: "0",
            fontFamily: "Ubuntu, sans-serif",
            marginRight: "20px",
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "normal",
            marginTop: "80px",
          }}
        >
          Finish
        </Button>
      </Paper>
    </Box>
  );
}

export default DownloadPage;
