import React, { useState } from "react";
import "../../../src/App.css";
import "../forms/Form.css";
import { TextFieldStyled } from "./Form";
import { useInputValues, withInputValues } from "../../contexts/inputContext";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../constants/constants";
import { ForkRight } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const Config = () => {
  const { inputValues, setInputValues } = useInputValues();
  const handleNavItemChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputValues.sideBar.navItems];
    list[index][name] = value;
    setInputValues({
      ...inputValues,
      sideBar: {
        navItems: list,
      },
    });
  };
  const {
    _id,
    createdBy,
    isYamlResponse,
    table_type,
    updatedAt,
    yamlUrl,
    ...values
  } = inputValues;
  const handleInputChange = (e) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [e.target.name]: e.target.value,
    }));
  };

  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const { applicationType } = location.state || {};
  const handleClickOpen = () => {
    setOpen(true);
  };
console.log(applicationType,'applicationType');
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddNavItem = () => {
    setInputValues({
      ...inputValues,
      sideBar: {
        navItems: [...inputValues.sideBar.navItems, { title: "", href: "" }],
      },
    });
  };
  const handleRemoveNavItem = (index) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      sideBar: {
        ...prevInputValues.sideBar,
        navItems: prevInputValues.sideBar.navItems.filter(
          (_, i) => i !== index
        ),
      },
    }));
    setOpen(false);
  };
  const handleShowSideBarChange = (e) => {
    setInputValues({
      ...inputValues,
      appBar: {
        ...inputValues.appBar,
        showSideBar: e.target.checked,
      },
    });
  };

  const handleAuthSwitchChange = (event) => {
    setInputValues({
      ...inputValues,
      auth: {
        isAuthenticate: event.target.checked,
      },
    });
  };
  // const handleAppTitleChange = (e) => {
  //   setInputValues({
  //     ...inputValues,
  //     appBar: {
  //       ...inputValues.appBar,
  //       title: e.target.value,
  //     },
  //   })
  // }
  const [error, setError] = useState("");
  const handleChangeSideBar = (e) => {
    setInputValues({
      ...inputValues,
      appBar: { ...inputValues.appBar, sideBarWidth: Number(e.target.value) },
    });
    if (
      handleChangeSideBar === "" ||
      (parseInt(e.target.value) >= 60 && parseInt(e.target.value) <= 250)
    ) {
      setError("");
    } else {
      setError("Please enter a number between 60 and 250.");
    }
  };

  const editApplicationData = async () => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };

      const response = await fetch(
        `${BASE_URL}/applications/${inputValues._id}`,
        requestOptions
      );

      if (response.ok) {
        const response = await fetch(
          `${BASE_URL}/applications/${inputValues._id}`
        );
        toast.success("Data updated successfully");
        const data = await response.json();
        if (response.ok) {
          setInputValues({ ...data?.data, isYamlResponse: true ,orgName:inputValues.orgName});
        } else {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(`An error occurred: ${error.message}`);
    }
  };
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {" "}
        <Button
          sx={{
            padding: "8px 40px",
            backgroundColor: "#808080",
            color: "#fff",
            cursor: "pointer",
            height: "45px",
            flexGrow: "0",
            fontFamily: "Ubuntu, sans-serif",

            fontSize: "18px",
            fontWeight: "700",
            borderRadius: "30px",
            lineHeight: "normal",
            marginBottom: "1rem",
            textTransform: "none",
          }}
          variant="contained"
          color="primary"
          onClick={editApplicationData}
          className="Update-btn"
        >
          Update
        </Button>
        <ToastContainer />
      </Box>{" "}
      <div sx={{ minHeight: "60vh" }}>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={5}>
            <TextFieldStyled
              fullWidth
              label="App Name"
              variant="outlined"
              value={inputValues.appName}
              name="appName"
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={5}>
            <TextFieldStyled
              fullWidth
              label="App Base URL"
              variant="outlined"
              name="baseUrl"
              value={inputValues.baseUrl}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "0.75rem",
          }}
        >
          <Grid item xs={5}>
            <FormControlLabel
              control={
                <Switch
                  checked={inputValues.appBar.showSideBar}
                  onChange={handleShowSideBarChange}
                />
              }
              label="Show LHS Menu?"
              labelPlacement="start"
            />
          </Grid>
          {inputValues.appBar.showSideBar && (
            <Grid item xs={5}>
              <TextFieldStyled
                label="LHS Menu Width"
                onChange={handleChangeSideBar}
                value={
                  inputValues.appBar.sideBarWidth
                    ? inputValues.appBar.sideBarWidth
                    : "NA"
                }
                name="sideBarWidth"
                type="number"
                min={60}
                max={250}
              />
              {error && (
                <p style={{ color: "red", marginTop: "-15px" }}>{error}</p>
              )}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: "0.75rem",
          }}
        >
          <Grid item xs={8}>
            {applicationType !== "mfe application" && (
              <FormControlLabel
                control={
                  <Switch
                    checked={inputValues.auth.isAuthenticate}
                    onChange={handleAuthSwitchChange}
                  />
                }
                label="Enable Authentication?"
                labelPlacement="start"
              />
            )}
          </Grid>
          {inputValues.appBar.showSideBar && (
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{
                  marginLeft: "75px",
                  width: "201px",
                  borderRadius: "30px",
                  padding: "8px 40px",
                  backgroundColor: "#1C242C",
                  color: "#fff",
                  cursor: "pointer",
                  height: "45px",
                  flexGrow: "0",
                  fontFamily: "Ubuntu, sans-serif",
                  marginBottom: "1rem",
                  fontSize: "18px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "none",
                }}
                variant="contained"
                color="primary"
                onClick={handleAddNavItem}
              >
                <AddIcon aria-label="add" />
                Menu Item
              </Button>
            </Grid>
          )}
        </Grid>

        {inputValues.appBar.showSideBar && (
          <div>
            {inputValues.sideBar.navItems.map((item, i) => (
              <Grid
                container
                spacing={2}
                key={i}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={5} sx={{ marginTop: "1rem" }}>
                  <TextFieldStyled
                    label={`Menu Item Title`}
                    name="title"
                    variant="outlined"
                    fullWidth
                    value={item.title}
                    onChange={(e) => handleNavItemChange(e, i)}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextFieldStyled
                    label={`Menu Item Link`}
                    variant="outlined"
                    name="href"
                    value={item.href}
                    onChange={(e) => handleNavItemChange(e, i)}
                    fullWidth
                    sx={{ marginTop: "1rem" }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <React.Fragment>
                    <IconButton className="delete-icon">
                      <DeleteIcon
                        variant="contained"
                        aria-label="delete"
                        onClick={handleClickOpen}
                        // onClick={() => handleremoveColumn(i)}
                      />
                    </IconButton>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Delete"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to permanently delete?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => handleRemoveNavItem(i)}
                          autoFocus
                        >
                          Yes
                        </Button>
                        <Button onClick={handleClose}>No</Button>
                      </DialogActions>
                    </Dialog>
                  </React.Fragment>
                  {/* <IconButton class="delete-icon">
                    <DeleteIcon
                      variant="contained"
                      aria-label="delete"
                      onClick={() => handleRemoveNavItem(i)}
                    />
                  </IconButton> */}
                  {/* <Button
                    sx={{
                      padding: '8px 40px',
                      backgroundColor: '#1C242C',
                      color: '#fff',
                      cursor: 'pointer',
                      height: '45px',
                      flexGrow: '0',
                      fontFamily: 'Ubuntu, sans-serif',
                      marginRight: '20px',
                      fontSize: '18px',
                      fontWeight: '700',
                      borderRadius: '30px',
                      lineHeight: 'normal',
                    }}
                    variant='contained'
                    color='primary'
                    onClick={() => handleRemoveNavItem(i)}
                  >
                    Remove
                  </Button> */}
                </Grid>
              </Grid>
            ))}
            {/* <Button
              sx={{
                marginTop: '1rem',
                marginLeft: '15px',
                width: '200px',
                borderRadius: '30px',
                padding: '8px 40px',
                backgroundColor: '#1C242C',
                color: '#fff',
                cursor: 'pointer',
                height: '45px',
                flexGrow: '0',
                fontFamily: 'Ubuntu, sans-serif',
                marginBottom: '1rem',
                fontSize: '18px',
                fontWeight: '700',

                lineHeight: 'normal',
              }}
              variant='contained'
              color='primary'
              onClick={handleAddNavItem}
            >
              Add Menu Item
            </Button> */}
            {/* <Typography
              variant='h6'
              sx={{
                fontFamily: 'Ubuntu, sans-serif',
                fontWeight: '500',
                fontSize: '22px',
                lineHeight: '25px',
                color: '#1C242C',
                textAlign: 'left',
                marginBottom: '0.75rem',
              }}
              gutterBottom
            >
              App Nav Bar Config :
            </Typography>
            <TextFieldStyled
              label='AppBar Title Bar'
              variant='outlined'
              fullWidth
              value={inputValues?.appBar.title}
              onChange={handleAppTitleChange}
            /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Config;
