import React, { createContext, useContext, useState } from 'react';
import { inputInitialState } from '../constants/inputconstants';
import { data } from '../data';

const InputContext = createContext(null);

export const useInputValues = () => {
  const contextValues = useContext(InputContext);

  if (!contextValues) {
    throw new Error('useInputValues must be used within an InputProvider');
  }

  const { inputValues, setInputValues } = contextValues;

  return { inputValues, setInputValues };
};

const InputProvider = (props) => {
  const [inputValues, setInputValues] = useState(inputInitialState)

  return (
    <InputContext.Provider value={{ inputValues, setInputValues }}>
      {props.children}
    </InputContext.Provider>
  );
};

export const withInputValues = (Component) => {
  const WithInputValues = (props) => {
    return (
      <InputProvider>
        <Component {...props} />
      </InputProvider>
    );
  };
  return WithInputValues;
};
