import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { getFormData, getListData } from "../forms/helper";
import { useInputValues } from "../../contexts/inputContext";
import { moduleInitialState } from "../../constants/inputconstants";
import { v4 as uuidv4 } from "uuid";

export const TextFieldStyled = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  // marginBottom: '1rem',
  width: "100%",
});

const FormModule = (props) => {
  const {
    selectedModuleIndex,
    formData,
    formList,
    handleAddInput,
    handleCheckboxInputChange,
    handleFormListInputChange,
    handleremoveFormList,
    handleToggleChange,
    handleChange,
    moduleState,
    handleClickOpen,
    handleClose,
    toggleValue,
    setToggleValue,
    open,
    updateFormList,
    setFormList,
    selectedModuleFormId
  } = props;

  // Group formList items by formIndex
  // const formIndexGroups = formList.reduce((acc, item) => {
  //   if (!acc[item.formIndex]) {
  //     acc[item.formIndex] = [];
  //   }
  //   acc[item.formIndex].push(item);
  //   return acc;
  // }, {});

  // const { inputValues, setInputValues } = useInputValues();
  const [numPages, setNumPages] = useState(formData?.data?.multiPage);
 
  // const module = inputValues.modules.length
  //   ? inputValues.modules[0]
  //   : moduleInitialState;

  const inputOptions = [
    {
      value: "TextField",
      label: "String",
    },
    {
      value: "Select",
      label: "String with Enum ",
    },
    {
      value: "DatePicker",
      label: "Date",
    },
    {
      value: "CheckBox",
      label: "boolean",
    },
  ]
    
  
useEffect(()=>{
  setCurrentPage(1)
  setNumPages(formData?.data?.multiPage)
  
},[selectedModuleFormId,formData?.data?.multiPage])
  const timeOptions = ["2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const StyledToggleButton = styled(ToggleButton)({
    height: "32px",
    width: "58px",
    "&.Mui-selected": {
      backgroundColor: "black",
      color: "white",
    },
  });

  // State to keep track of current page
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  // Update formList when the number of pages changes
  

  const getFistPageData = () => {
    const numParameters = formList.length
    const parametersPerPage = Math.ceil(numParameters / Math.max(numPages, 1))
    let startIndex = 0
    let formIndex = 1
    //console.log("---useEffect---formList------", formList);
    const updatedFormIndexGroups = formList.reduce((acc, item) => {
      if (!acc[item.formIndex]) {
        acc[item.formIndex] = []
      }
      acc[item.formIndex].push(item)
      return acc
    }, {})
    //console.log('-----useEffect--------updatedFormIndexGroups------------',updatedFormIndexGroups)
    for (let i = 0; i < formList.length; i++) {
      formList[i].formIndex = formIndex
      if ((i + 1) % parametersPerPage === 0) {
        startIndex = i + 1
        formIndex++
      }
    }
    //return updatedFormIndexGroups;
    // Ensure that currentPageItems is updated with the correct formIndexGroups
    //console.log('-----useEffect--------beforeCalc------------',updatedFormIndexGroups[currentPage].length)
    //setCurrentPageItems(updatedFormIndexGroups[currentPage] || []);
    //console.log('--------useEffect-----currentPageItems----',currentPage," :: ",currentPageItems);
  }

  useEffect(() => {
    
    // Calculate the number of parameters per page
    const parametersPerPage = Math.ceil(
      formList.length / Math.max(numPages, 1)
    );

    // Update formIndex in formList based on the number of pages
    formList
      .sort((a, b) => a - b)
      .forEach((item, index) => {
        // Calculate formIndex for each item
        item.formIndex = Math.ceil((index + 1) / parametersPerPage);
      });
    // Filter items based on current page
    const itemsOnCurrentPage = formList.filter(
      (item) => item.formIndex === currentPage
    );

    // Set current page items
    setCurrentPageItems(itemsOnCurrentPage)
  }, [numPages, currentPage, formList])

  // Number of parameters to display per page
  // const parametersPerPage = 5;

  // // Calculate total number of pages
  // const totalPages = numPages;

  // Calculate starting index of current page
  // const startIndex = (currentPage - 1) * parametersPerPage;

  // // Calculate ending index of current page
  // const endIndex = Math.min(startIndex + parametersPerPage, formList.length);

  // Function to handle page change

  const updateFormListBasedOItemIndex = (updatedIndex) => {};
  const handlePageChange = (page) => {
    // Ensure page number is within valid range
    const newPage = Math.max(1, Math.min(page, numPages));
    setCurrentPage(newPage);
  };

  const handlePageUpdate = (e) => {
    
    setNumPages(e.target.value);
    setCurrentPage(1);
    //getFistPageData()
  };

  const handleParameterMove = (e, index) => {
    const { name, value } = e.target;
    let list = [...currentPageItems];
    list[index][name] = value;
    setCurrentPageItems(list);
  };

  const handleParameterMoveForm = (e, index) => {
    const { name, value } = e.target;
    let list = [...currentPageItems];
    list[index][name] = e.target.checked;
    setCurrentPageItems(list);
  };
  const handleParameterMoveUp = (e, index) => {
    const { name, value } = e.target;

    let list = [...currentPageItems];

    if (value > list[index][name]) {
      
      list[index][name] = value + 1;
    } else {
      list[index][name] = value - 1;
    }

    setFormList(formList);

    formList.sort((a, b) => a.formIndex - b.formIndex);

    // Filter items based on current page
    const itemsOnCurrentPage = formList.filter(
      (item) => item.formIndex === currentPage
    );
    // Set current page items
    setCurrentPageItems(itemsOnCurrentPage);
  };

  const handleDualButtonChange = (newValue) => {
    
    setToggleValue(newValue);
    if (newValue === "Tile") {
      setNumPages(1);
    } else {
      setNumPages(1);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ alignItems: "center", margin: "1rem", padding: 0 }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          xs={12}
        >
          {" "}
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              fontFamily: "Ubuntu, sans-serif",
              fontWeight: "500",
              fontSize: "22px",
              lineHeight: "25px",
              color: "#1C242C",
              textAlign: "left",
              padding: 0,
            }}
            variant="h4"
            component="h1"
            gutterBottom
          >
            Is this a multipage form or single page form?
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={toggleValue}
            exclusive
            onChange={(event, newValue) => handleDualButtonChange(newValue)}
          >
            <StyledToggleButton
              sx={{ width: "140px", fontSize: "12px" }}
              value="Multiple Page"
            >
              Multiple Page
            </StyledToggleButton>
            <StyledToggleButton
              sx={{ width: "120px", fontSize: "12px" }}
              value="Single Page"
            >
              Single Page
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      {toggleValue === "Multiple Page" && (
        <Grid
          container
          spacing={1}
          sx={{ alignItems: "center", margin: 0, padding: 0 }}
        >
          <Grid item xs={6}>
            <Typography
              sx={{
                fontFamily: "Ubuntu, sans-serif",
                fontWeight: "500",
                fontSize: "22px",
                lineHeight: "25px",
                color: "#1C242C",
                textAlign: "left",
                padding: 0,
              }}
              variant="h4"
              component="h1"
              gutterBottom
            >
              No. of Pages?
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              sx={{
                minWidth: "70%",
                marginTop: "20px",
                boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
                marginBottom: "20px",
              }}
              fullWidth={true}
              select
              name="expiry"
              label="No. Pages"
              value={numPages}
              onChange={(e) => {
                handlePageUpdate(e);
              }}
              InputLabelProps={{
                style: {
                  fontStyle: "ubuntu",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                },
              }}
            >
              {timeOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  disabled={option > formList.length / 2}
                >
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      )}

      {/* Render form elements for the current page */}
      {currentPageItems.map((item, index) => (
        <Grid container spacing={2} key={item.uuid}>
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Checkbox
              name="display"
              checked={item.display}
              onChange={(e) => handleParameterMoveForm(e, index)}
            />
          </Grid>
          <Grid item xs={4.5}>
            <TextFieldStyled
              label={`Input Label`}
              variant="outlined"
              name="label"
              value={item.label}
              onChange={(e) => handleParameterMove(e, index)}
              fullWidth
              sx={{ marginTop: "1rem" }}
            />
          </Grid>
          <Grid item xs={3.5} sx={{ marginTop: "1rem" }}>
            <TextFieldStyled
              label={`Input Type`}
              name="componentName"
              variant="outlined"
              select
              fullWidth
              value={item.componentName}
              onChange={(e) => handleFormListInputChange(e, index)}
            >
              {inputOptions.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextFieldStyled>
          </Grid>
          {toggleValue === "Multiple Page" && (
            <Grid item xs={1}>
              <TextField
                sx={{
                  minWidth: "70%",
                  marginTop: "20px",
                  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
                  marginBottom: "20px",
                }}
                fullWidth={true}
                select
                name="formIndex"
                label="Move to Page"
                value={item.formIndex}
                onChange={(e) => {
                  handleParameterMoveUp(e, index);
                }}
                InputLabelProps={{
                  style: {
                    fontStyle: "ubuntu",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  },
                }}
              >
                {Array.from({ length: numPages }, (_, index) => index + 1).map(
                  (pageNumber) => (
                    <MenuItem key={pageNumber} value={pageNumber}>
                      {pageNumber}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          )}
          <Grid
            item
            xs={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton className="delete-icon">
              <DeleteIcon
                variant="contained"
                aria-label="delete"
                onClick={() => handleClickOpen(index)}
              />
            </IconButton>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to permanently delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleremoveFormList(index)} autoFocus>
                  Yes
                </Button>
                <Button onClick={handleClose}>No</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      ))}
      {/* Pagination */}
      {toggleValue === "Multiple Page" && (
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <Button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Typography>
              Page {currentPage} of {numPages}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={currentPage === numPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FormModule;
