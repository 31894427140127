import { Box, Paper } from '@mui/material'
import React, { useEffect } from 'react'
import spa from '../../assets/Spa.svg'
import mfe from '../../assets/mfe.svg'
import topArrow from '../../assets/Line 503.svg'
import './Home.css'
import { Link } from 'react-router-dom';
import TopArrow from '../svg/TopArrow'
import { useInputValues } from '../../contexts/inputContext'
import { inputInitialState } from '../../constants/inputconstants'
function Home({setDynamicTitle}) {
  const {inputValues, setInputValues} = useInputValues()
  useEffect(()=>{
    setDynamicTitle('')
    setInputValues(inputInitialState)
  },[])
  return (
    <Box className='background-image-div'>
      <Box className='paper-wrapper'>
        <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <Box className='card-wrapper'>
            <Box className='arrow-circle'></Box>
            <TopArrow />
            <Box>
              <img
                style={{ width: '120px', }}
                src={spa}
                alt='spa'
              />
              <p
                className='card-heading'
                style={{
                  margin: '10px 0 0 0',
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                React js Single Page
              </p>
              <p className='card-heading' style={{ margin: '0px' }}>
                {' '}
                Application
              </p>
            </Box>
          </Box></Link>
          <Link to="/rootform" style={{ textDecoration: 'none' }}>
          <Box className='card-wrapper'>
            <Box className='arrow-circle'></Box>
            <TopArrow />
            <Box>
              <img
                src={mfe}
                style={{ width: '120px'}}
                alt='mfe'
              />
              <p className='card-heading' style={{ margin: '10px 0 0 0' }}>
                Composable 
              </p>
              <p className='card-heading' style={{ margin: '0px' }}>
              MFE's
              </p>
            </Box>
          </Box>
          </Link>
        </Box>
        
      </Box>
    </Box>
  )
}

export default Home
