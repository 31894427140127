export const data = {
  _id: '65f7dfc8d6932b2e9cd625f4',
  appName: 'Savings Accounts',
  baseUrl: 'http://localhost:8081',
  destinationPath: '/home/ec2-user/projects/',
  appBar: {
    title: 'Savings Account',
    showSideBar: true,
  },
  auth: {
    isAuthenticate: false,
  },
  sideBar: {
    navItems: [
      {
        title: 'Deposits',
        href: '/deposits/list',
      },
      {
        title: 'Withdrawal',
        href: '/withdrawal/list',
      },
      {
        title: 'Transactions',
        href: '/transactions/list',
      },
    ],
  },
  theme: {
    palette: {
      primary: {
        main: '#fc031d',
      },
      secondary: {
        main: '#197322',
      },
      tertiary: {
        main: '#FF5745',
      },
      background: {
        default: '#ffffff',
      },
      success: {
        main: '#4caf50',
      },
      text: {
        primary: '#000000',
      },
      type: 'light',
      divider: '#000000',
    },
  },
  modules: [
    {
      name: 'Deposit',
      _id: '65f7dfcdd6932b2e9cd625f7',
      form_id: '65f7e12dfbe3e8a00fbff8d7',
    },
    {
      name: 'withdrawal',
      _id: '65f7dfcdd6932b2e9cd625fa',
      form_id: '65f7e12dfbe3e8a00fbff8da',
    },
    {
      name: 'transaction',
      _id: '65f7dfcdd6932b2e9cd625fd',
    },
  ],
}
export const formListData = {
  status: true,
  message: "Data of 65fd3d23799d8b526455557c's moduleForm is below ",
  data: {
    _id: "65fd3d23799d8b526455557c",
    application_id: "65fd3d23799d8b5264555578",
    module_id: "65fd3d23799d8b526455557b",
    multiPage: 3,
    componentName: "Grid",
    props: {
      container: true,
      rowSpacing: 2,
      columnSpacing: 1
    },
    children: [
      {
        componentName: "Grid",
        props: {
          container: true,
          rowSpacing: 2,
          columnSpacing: 1,
          dynamicStyle: "{{\"display\":formGroups.group1?\"flex\":\"none\"}}"
        },
        children: [
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "ProductInstanceReference",
                  name: "savingsAccountFacility.productInstanceReference",
                  componentGroup: "grp1"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "SavingsAccountNumber",
                  name: "savingsAccountFacility.savingsAccountNumber",
                  componentGroup: "grp1"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "CustomerReference",
                  name: "savingsAccountFacility.customerReference",
                  componentGroup: "grp1"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "BankBranchLocationReference",
                  name: "savingsAccountFacility.bankBranchLocationReference",
                  componentGroup: "grp1"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "AccountType",
                  name: "savingsAccountFacility.accountType",
                  componentGroup: "grp1"
                },
                componentName: "TextField"
              }
            ]
          }
        ]
      },
      {
        componentName: "Grid",
        props: {
          container: true,
          rowSpacing: 2,
          columnSpacing: 1,
          dynamicStyle: "{{\"display\":formGroups.group2?\"flex\":\"none\"}}"
        },
        children: [
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "AccountCurrency",
                  name: "savingsAccountFacility.accountCurrency",
                  componentGroup: "grp2"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "EntitlementOptionSetting",
                  name: "savingsAccountFacility.entitlementOptionSetting",
                  componentGroup: "grp2"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "RestrictionOptionSetting",
                  name: "savingsAccountFacility.restrictionOptionSetting",
                  componentGroup: "grp2"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "PositionLimitType",
                  name: "savingsAccountFacility.positionLimitType",
                  componentGroup: "grp2"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "TransactionDateType",
                  name: "savingsAccountFacility.transactionDateType",
                  componentGroup: "grp2"
                },
                componentName: "TextField"
              }
            ]
          }
        ]
      },
      {
        componentName: "Grid",
        props: {
          container: true,
          rowSpacing: 2,
          columnSpacing: 1,
          dynamicStyle: "{{\"display\":formGroups.group3?\"flex\":\"none\"}}"
        },
        children: [
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "TransactionType",
                  name: "depositsandWithdrawals.transactionType",
                  componentGroup: "grp3"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "TransactionAmount",
                  name: "depositsandWithdrawals.transactionAmount",
                  componentGroup: "grp3"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "TransactionDescription",
                  name: "depositsandWithdrawals.transactionDescription",
                  componentGroup: "grp3"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8
            },
            children: [
              {
                props: {
                  fullWidth: "true",
                  required: true,
                  label: "AccountLimitBreachResponse",
                  name: "depositsandWithdrawals.accountLimitBreachResponse",
                  componentGroup: "grp3"
                },
                componentName: "TextField"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 8,
              justifyContent: "flex-start",
              rowSpacing: 2
            },
            children: [
              {
                props: {
                  variant: "contained"
                },
                componentName: "SmartButton"
              }
            ]
          }
        ]
      },
      {
        componentName: "Grid",
        props: {
          style: {
            marginTop: "10px"
          },
          container: true,
          rowSpacing: 8,
          columnSpacing: 2,
          direction: "column"
        },
        children: [
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 10
            },
            children: [
              {
                props: {
                  label: "Previous",
                  componentGroup: "grp4",
                  dynamicStyle: "{{\"display\":formGroups.group2 || formGroups.group3 ? 'flex' : 'none'}}",
                  variant: "contained"
                },
                componentName: "Button"
              }
            ]
          },
          {
            componentName: "Grid",
            props: {
              item: true,
              xs: 10
            },
            children: [
              {
                props: {
                  label: "Next",
                  componentGroup: "grp4",
                  dynamicStyle: "{{\"display\":formGroups.group1 || formGroups.group2 ? 'flex' : 'none'}}",
                  variant: "contained"
                },
                componentName: "Button"
              }
            ]
          }
        ]
      }
    ]
  }
}
