import React, { useState } from 'react';
import logo from '../../assets/logo2.png';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, Menu, MenuItem } from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useInputValues } from '../../contexts/inputContext';


const StyledAvatar = styled(Avatar)({
  height: '40px',
  width: '40px',
});

function Header({title, onSignOut}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const userName = sessionStorage.getItem("userName") || '';
  const { inputValues, setInputValues } = useInputValues();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const appType = queryParams.get("appType")
  
  const handleClick = (event) => {
    event.preventDefault();
    navigate("/");
  };
 
  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };



  return (
    <div className='header-container'>
      <a href="/" onClick={handleClick}>
        <img style={{ height: '85px' }} src={logo} alt="logo" />
      </a>
      <div className='heading-container'>
        <p onClick={handleClick} className='heading-header-main'>UI Code Generator</p>
        <p className='heading-header-sub'>{inputValues.orgName && location.pathname !== "/home" && 'MFE ' }{title} {location.pathname === "/rootform" && appType =='root' && "Root Configuration"}</p>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '10px', color: '#006E74', fontFamily: 'Ubuntu'}}>{userName}</span>
        <StyledAvatar sx={{ backgroundColor: '#006E74' }} onClick={handleAvatarClick} src="/broken-image.jpg" />
        
      </div>


      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem>Settings</MenuItem>
        <MenuItem onClick={onSignOut}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default Header;
