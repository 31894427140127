import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography, IconButton, InputAdornment, Link } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Auth } from "aws-amplify";

const SignIn = ({ setJwtToken }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signInState, setSignInState] = useState({ username: "", password: "" });
  const [errors, setErrors] = useState({ username: "", password: "" });

  const handleSignInChange = (field, e) => {
    setSignInState({ ...signInState, [field]: e.target.value });
    setErrors({ ...errors, [field]: "" });
  };

  const validateForm = () => {
    const newErrors = { username: "", password: "" };
    let valid = true;

    if (!signInState.username) {
      newErrors.username = "*Mandatory";
      valid = false;
    }
    if (!signInState.password) {
      newErrors.password = "*Mandatory";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      const user = await Auth.signIn(signInState.username, signInState.password);
      const token = user.signInUserSession.idToken.jwtToken;

      sessionStorage.setItem("MFE_Token", token);
      setJwtToken(token);
      sessionStorage.setItem("userName", signInState.username);

      navigate("/");
    } catch (error) {
      console.log("Error signing in", error);
      setErrors({ ...errors, password: "Username or Password is Wrong" });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: 'calc(100vh - 64px)',
        paddingTop: 4,
      }}
    >
      <Box sx={{ width: 450, border: '1px solid #e0e0e0', backgroundColor: "white", borderRadius: 1, paddingLeft: 3, paddingRight: 3 }}>
        <Box sx={{ minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', fontFamily: 'Ubuntu', fontSize: '16px' }}>
          <Box component="form" sx={{ mt: -5 }}>
            <Typography variant="h7" sx={{ color: '#304050', fontFamily: 'Ubuntu' }}>
              Username
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter your username"
              onChange={(e) => handleSignInChange("username", e)}
              value={signInState.username}
              sx={{ marginTop: '5px', marginBottom: '10px', fontFamily: 'Ubuntu', fontSize: '16px' }}
              error={Boolean(errors.username)}
              helperText={errors.username}
              FormHelperTextProps={{ sx: { position: 'absolute', right: 0, top: '-20px', color: 'red', fontFamily: 'Ubuntu' } }}
            />
            <Typography variant="h7" sx={{ color: '#304050', fontFamily: 'Ubuntu' }}>
              Password
            </Typography>
            <TextField
              fullWidth
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              value={signInState.password}
              onChange={(e) => handleSignInChange("password", e)}
              sx={{ marginTop: '5px', marginBottom: '5px', fontFamily: 'Ubuntu', fontSize: '16px' }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        height: 55,
                        width: 2,
                        backgroundColor: 'rgba(0, 0, 0, 0.23)',
                        marginRight: 1,
                      }}
                    />
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2, textTransform: 'none', backgroundColor: '#007887', color: '#fff', fontFamily: 'Ubuntu', fontWeight: 'bold' }}
              onClick={handleLogin}
            >
              Sign in
            </Button>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', fontFamily: 'Ubuntu' }}>
              <Link href="#" underline="hover">
                Forgot your password?
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
