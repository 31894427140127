import React from 'react'

const TopArrow = () => {
  return (
    <svg
      className='arrow-img'
      width='19'
      height='17'
      viewBox='0 0 19 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.6156 4.21673C18.7356 3.53688 18.2817 2.8885 17.6019 2.76852L6.52306 0.813438C5.8432 0.693464 5.19482 1.14733 5.07484 1.82719C4.95487 2.50704 5.40874 3.15542 6.08859 3.2754L15.9364 5.01325L14.1986 14.8611C14.0786 15.5409 14.5325 16.1893 15.2123 16.3093C15.8922 16.4293 16.5406 15.9754 16.6605 15.2955L18.6156 4.21673ZM1.71683 16.4928L18.1015 5.02354L16.6678 2.97546L0.283172 14.4447L1.71683 16.4928Z'
        fill='#1C242C'
      />
    </svg>
  )
}

export default TopArrow