import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  CircularProgress,
  Link,
  Modal,
} from "@mui/material";
import styled from "@emotion/styled";
import { useInputValues, withInputValues } from "../../contexts/inputContext";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../constants/constants.js";
import YamlViewer from "../YamlViewer/YamlViewer.jsx";
import CloseIcon from "@mui/icons-material/Close";
import Doc from "../../assets/Doc.png";
const TextFieldStyled = styled(TextField)({
  marginBottom: "20px",
  "& .MuiInputLabel-root": {
    fontFamily: "ubuntu",
    fontSize: "1.1rem",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "primary.main",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.main",
    },
  },
  boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.25)",
  borderRadius: "10px",
  height: "auto",
  // marginBottom: '1rem',
  width: "100%",
});

const ButtonStyled = styled(Button)({
  borderRadius: "30px",
  padding: "8px 40px",
  backgroundColor: "#1C242C",
  color: "#fff",
  cursor: "pointer",
  height: "45px",
  flexGrow: "0",
  fontFamily: "Ubuntu, sans-serif",
  width: "172px",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "normal",
});

const StyledToggleButton = styled(ToggleButton)({
  height: "32px",
  width: "58px",
  "&.Mui-selected": {
    backgroundColor: "black",
    color: "white",
  },
});

const FincuroUICodeGeneratorPage = () => {
  const { inputValues, setInputValues } = useInputValues();
  const [applicationName, setApplicationName] = useState("");
  const [developersEmails, setDevelopersEmails] = useState("");
  const [yamlUrl, setYamlUrl] = useState("");
  const [yamlResponse, setYamlResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleValue, setToggleValue] = useState("YES");
  const [showYamlError, setShowYamlError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [open, setOpen] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here
  };
  const { applicationType } = location.state || {};
console.log(location,'location');
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateMultipleEmails = (value) => {
    const emails = value.split(/[;,]/);
    setIsEmailValid(emails.every(validateEmail));
  };

  const validateYaml = async () => {
    if (!yamlUrl) return;

    try {
      const response = await fetch(
        "https://scaffolding-api.fincuro.com/api/validateYaml",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            yamlUrl: yamlUrl,
          }),
        }
      );
      const data = await response.json();
      const yamlStatus = data.status === "success";

      if (yamlStatus) {
        setShowYamlError(false);
        setDisableButton(false);
      } else {
        setShowYamlError(true);
        setDisableButton(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setShowYamlError(true);
      setDisableButton(true);
      throw error;
    }
  };

  const createUiGenConfigFile = async () => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ yamlUrl: yamlUrl }),
      };

      const response = await fetch(
        `${BASE_URL}/api/createUiGenConfigFile`,
        requestOptions
      );

      if (response.ok) {
        const data = await response.json();
        setYamlResponse(data.data);
        setInputValues({
          orgName: inputValues.orgName,
          ...data?.data,
          isYamlResponse: true,
        });
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      throw error;
    }
  };

  const onClickHandler = async () => {
    try {
      await createUiGenConfigFile();
      navigate("/form");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleBackStep = () => {
    navigate("/");
  };

  // const createJson = async () => {
  //   if (!yamlUrl) return;

  //   try {
  //     const response1 = await fetch(
  //       "https://scaffolding-api-dev.fincuro.com/api/validateYaml",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           yamlUrl: yamlUrl,
  //         }),
  //       }
  //     );

  //     const data1 = await response1.json();
  //     const yamlStatus = data1.status === "success";

  //     if (yamlStatus) {
  //       const body = {
  //         yamlUrl: yamlUrl,
  //       };

  //       setLoading(true);

  //       const requestOptions = {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(body),
  //       };

  //       const response = await fetch(
  //         `${BASE_URL}/api/createUiGenConfigFile`,
  //         requestOptions
  //       );
  //       const data = await response.json();
  //       if (response.ok) {
  //         console.log(data.data);
  //         setYamlResponse(data.data);
  //         setInputValues({ ...data?.data, isYamlResponse: true });
  //         setLoading(false);
  //       } else {
  //         throw new Error(
  //           `Error: ${response.status} - ${response.statusText}`
  //         );
  //       }
  //       setShowYamlError(false);
  //       setDisableButton (false)
  //     } else {
  //       setShowYamlError(true);
  //       setDisableButton(true)
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     setLoading(false);
  //     setShowYamlError(true);
  //     setDisableButton(true)
  //     throw error;
  //   }
  // };
  console.log(inputValues);
  useEffect(() => {
    if (toggleValue == "NO") {
      setShowYamlError(false);
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  }, [toggleValue]);

  if (loading)
    return (
      <CircularProgress
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        disableShrink
      />
    );
  return (
    <Box
      className="background-image-div"
      sx={{ padding: "27px 350px 27px 350px", margin: "0" }}
    >
      <Paper sx={{ padding: "25px 40px 25px 40px",minWidth:'672px', borderRadius: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ marginBottom: "2.5rem" }}>
            <button
              onClick={handleBackStep}
              style={{
                all: "unset",
                marginRight: "6rem",
                padding: "0",
                display: "flex",
                alignItems: "center",
                backgroundColor: "white",
                color: "#000000",
                cursor: "pointer",

                flexGrow: "0",
                fontFamily: "Ubuntu, sans-serif",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "normal",
              }}
            >
              <svg
                width="10"
                height="19"
                viewBox="0 0 10 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 17.625L2 9.3125L9 1"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
              &nbsp; &nbsp; Back
            </button>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography
                sx={{
                  fontFamily: "Ubuntu, sans-serif",
                  fontWeight: "500",
                  fontSize: "22px",
                  lineHeight: "25px",
                  color: "#1C242C",
                  textAlign: "left",
                  marginLeft: "5px",
                }}
                variant="h4"
                component="h1"
                gutterBottom
              >
                Microservice Available?
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <ToggleButtonGroup
                value={toggleValue}
                exclusive
                onChange={(event, newValue) => setToggleValue(newValue)}
              >
                <StyledToggleButton value="YES">YES</StyledToggleButton>
                <StyledToggleButton value="NO">NO</StyledToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          {toggleValue !== "YES" && (
            <TextFieldStyled
              sx={{ marginTop: "1rem" }}
              label="Application Name"
              name="applicationName"
              value={applicationName}
              onChange={(e) => setApplicationName(e.target.value)}
              variant="outlined"
            />
          )}

          {toggleValue === "YES" && (
            <TextFieldStyled
              label="Enter The path to YAML file"
              name="yamlUrl"
              value={yamlUrl}
              sx={{ marginTop: "1rem" }}
              onChange={(e) => setYamlUrl(e.target.value)}
              onBlur={validateYaml}
              variant="outlined"
            />
          )}

          {errorMessage && (
            <Typography sx={{ fontStyle: "italic" ,fontSize:'18px'}} color="error" variant="h6">
              Authoring UI tool is not ready yet coming soon
            </Typography>
          )}
          {applicationType === "mfe application" && (
            <TextFieldStyled
              label="Organization name"
              name="orgName"
              value={inputValues.orgName}
              sx={{ marginTop: "1rem" }}
              onChange={(e) =>
                setInputValues({ ...inputValues, orgName: e.target.value })
              }
              variant="outlined"
            />
          )}
          {!disableButton && (
            <Link
              sx={{
                color: "#1C242C",
                fontSize: "18px",
                textDecorationColor: "black",
                fontFamily: "Ubuntu, sans-serif",
                marginLeft: "10px",
              }}
              component="button"
              onClick={handleOpen}
            >
              View Yaml File
            </Link>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "697px",
                height: "473px",
                borderRadius: "10px",
                bgcolor: "white",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <Typography
                    sx={{
                      color: "#1C242C",
                      marginBottom: "20px",
                      fontFamily: "Ubuntu, sans-serif",
                      fontWeight: "700",
                      fontSize: "16px",
                      top: "269px",
                      textDecoration: "underline",
                    }}
                  >
                    Preview YAML file
                    <img alt="file" src={Doc} className="docImage" />
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <CloseIcon
                    variant="contained"
                    aria-label="close"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleClose()}
                  />
                </Grid>
              </Grid>

              <YamlViewer url={yamlUrl} />
            </Box>
          </Modal>

          {/* {showYamlError ? (
            <Typography
              style={{
                color: "red",
                marginTop: "-10px",
                marginBottom: "10px",
                marginLeft: 5,
              }}
            >
              Invalid Yaml
            </Typography>
          ) : null} */}
          <TextFieldStyled
            label="Developers, Emails"
            name="developersEmails"
            value={developersEmails}
            sx={{ marginTop: "1rem" }}
            onChange={(e) => setDevelopersEmails(e.target.value)}
            variant="outlined"
            onBlur={() => validateMultipleEmails(developersEmails)}
          />
          {!isEmailValid && (
            <Typography
              style={{ color: "red", marginBottom: 5, marginLeft: 5 }}
            >
              Please enter a valid email.
            </Typography>
          )}
          <ButtonStyled
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            type="submit"
            sx={{ marginTop: "1rem", textTransform: "none" }}
            onClick={onClickHandler}
            // disabled={disableButton || !isEmailValid}
          >
            Continue
          </ButtonStyled>
        </form>
      </Paper>
    </Box>
  );
};

export default FincuroUICodeGeneratorPage;
